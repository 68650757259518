import React from 'react';
import { TagList } from '../components/tag-list';
import Layout from '../components/layout';

const TagsPage = () => (
  <Layout>
    <h1>All tags</h1>
    <TagList showArticles={true} />
  </Layout>
);

export default TagsPage;
